input.control-required {
  border: 1px solid #ff544d !important;
  border-right: 0 !important;
  & + .input-group-text {
    border: 1px solid #ff544d !important;
    border-left: 0 !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &:focus {
    & + .input-group-text {
      border: 1px solid #ff544d !important;
      border-left: 0 !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}


textarea.ng-invalid.ng-touched {
  border: 1px solid #ff544d;
  &:focus {
    & + .input-group-append .input-group-text {
      border: 1px solid #ff544d !important;
    }
  }
}

input.ng-invalid.ng-touched {
  border: 1px solid #ff544d;

  & + .input-group-append .input-group-text {
    border: 1px solid #ff544d !important;
  }
  &:focus {
    & + .input-group-append .input-group-text {
      border: 1px solid #ff544d !important;
    }
  }
}

ng-select.ng-invalid.ng-touched {
  border: 1px solid #ff544d;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 9999s ease-out;
}


// input search
.input-group {

  .input-group-text,
  .form-control {
    //transition: none;
    transition: border-color .15s ease-in-out
  }

  .form-control {
    & + .input-group-append .input-group-text {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    /*&:focus {
      & + .input-group-append .input-group-text {
        border: solid 1px #6869fe !important;
      }
    }*/
  }
}
