// Feather Icon
i-feather {
  height: 20px !important;
  width: 20px !important;
}

// // Side Menu
#side-menu i-feather {
  height: 16px !important;
  width: 16px !important;
  line-height: 16px;
}

// Calendar
.fc .fc-col-header-cell-cushion {
  padding: 8px 4px !important;
  color: $gray-700 !important;
}

// Select2
@import "~@ng-select/ng-select/themes/default.theme.css";

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: $primary;
  border-color: $primary;
  margin-right: 0;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  color: $white;
  margin-bottom: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  gap: 5px;
}

.multi_default .ng-value-icon {
  display: none !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: initial !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #b8dbff;
  font-size: 16px;
  line-height: 16px;
  padding-left: 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: $gray-600 !important;
}

/**
*   Projects
*/
@import "~dropzone/dist/min/dropzone.min.css";

.dropzone .dz-message {
  font-size: 24px;
  min-height: 150px !important;
  border: none !important;
  background: #fff !important;
  color: #495057 !important;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li .step-indicator,
aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.navigable a:hover .step-indicator {
  font-size: 20px;
  color: $primary !important;
  line-height: 56px;
  width: 56px;
  height: 56px;
  border: 1px solid $blue-200;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.current .step-indicator {
  width: 56px;
  height: 56px;
  border: 1px solid $blue-200;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):after {
  width: 75%;
  height: 2px;
  left: 62%;
}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done .step-indicator {
  background-color: $blue-200;
  color: $primary;
  border-color: $blue-200;
}

/**
*   Profile page
*/
//Swiper
.swiper-sliders .swiper-button-next,
.swiper-sliders .swiper-button-prev {
  height: 30px !important;
  width: 30px !important;
  margin: auto 0;
  color: #fff !important;
  background-color: #039ef5;
}

.swiper-sliders .swiper-button-next,
.swiper-sliders .swiper-container-rtl .swiper-button-prev {
  right: 0 !important;
}

.swiper-sliders .swiper-button-prev,
.swiper-sliders .swiper-container-rtl .swiper-button-next {
  left: 0 !important;
}

.swiper-sliders .swiper-button-next:after,
.swiper-sliders .swiper-button-prev:after {
  font-size: 18px !important;
}

.swiper-sliders .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 !important
}

/**
* Faqs Page
*/
.general_faq .card-header {
  padding: 0;
  border: none;
}

.general_faq .card {
  box-shadow: none;
  margin: 0;
  border: none;
}

.general_faq .card-body {
  border-bottom: 1px solid $gray-dark-600;
}

.general_faq .btn-link {
  width: 100%;
  padding: 0;
  border: none;
}

.general_faq .collapsed .accordion-button {
  background-color: transparent;
  color: $gray-700;
  box-shadow: none;
  border-bottom: 1px solid $gray-dark-600;
}

.accordion_exp .card {
  border: 1px solid $gray-dark-600 !important;
}

.accordion_exp .card-body {
  border: none !important;
}

// table
/*.datatables {
  thead {
    tr {
      th {
        position: relative;

        &:before {
          content: "\F0143";
          position: absolute;
          right: 0;
          top: 7px;
          font: normal normal normal 24px/1 "Material Design Icons";
          opacity: 0.3;
        }

        &:after {
          content: "\F0140";
          position: absolute;
          right: 0;
          top: 14px;
          opacity: 0.3;
          font: normal normal normal 24px/1 "Material Design Icons";
        }
      }

      .asc {
        &:before {
          opacity: 1;
        }
      }

      .desc {
        &:after {
          opacity: 1;
        }
      }

      .none-sort {
        &:before, &:after {
          content: none;
        }
      }
    }
  }
}*/

/**
*   Ui Elements
*/
.alert-dismissible {
  .btn-close {
    font-size: 10px;
    padding: $alert-padding-y * 1.4 $alert-padding-x;
    background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
  }
}

.custom-alert {
  .close {
    background: transparent;
    border: 0;
    font-size: 10px;
    padding: 1.35rem 1.25rem;
    background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.5;
    width: 1em;
    height: 1em;
    z-index: 2;
    color: $btn-close-color;

    span {
      display: none;
    }
  }
}

// Carousel
.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: $white;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.carousel-dark .carousel-indicators li {
  background-color: $black;
}

// Colors
.col-xl-3:last-child .card .overflow-hidden {
  background-color: transparent !important;
}

/**
* Extended Ui
*/
// LightBox
@import "~ngx-lightbox/lightbox.css";

.vimeo_video iframe {
  width: 100%;
}

.vimeo_video #player {
  max-width: 100% !important;
}

// Range Slider
.ngx-slider .ngx-slider-pointer {
  cursor: pointer !important;
  width: 20px !important;
  height: 20px !important;
  top: -7px !important;
  right: -10px !important;
  background-color: $white !important;
  z-index: 3 !important;
  border-radius: 50% !important;
  border: 1px solid $gray-dark-500;
  box-shadow: inset 0 0 1px $white, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.ngx-slider .ngx-slider-pointer:after {
  display: none;
}

// Sweet alert
.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-icon.swal2-question {
  border-color: $info !important;
  color: $info !important;
}

.swal2-icon.swal2-warning {
  border-color: $warning !important;
  color: $warning !important;
}

// Rating
.stars {
  font-size: 30px;
  color: $warning;
}

.star {
  position: relative;
  display: inline-block;
  font-size: 30px;
  color: $warning;
}

.star_fill {
  color: $gray-500;
}

.full {
  color: #b0c4de;
}

.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: $warning;
}

.filled {
  color: #1e90ff;
}

.bad {
  color: #deb0b0;
}

.filled.bad {
  color: #ff1e1e;
}

// Swiper Slider
.swiper-pagination {
  transform: translateX(-50%) !important;
}

swiper > .swiper.s-wrapper .swiper-pagination .swiper-pagination-handle {
  line-height: 0;
  padding: 0 2px !important;
}

/**
* Forms
*/
// flatpicker
@import '~flatpickr/dist/flatpickr.css';

// Advance Form
.color_picker {
  width: auto;
  margin: 0 auto;
  display: inline-block;
}

.color_picker .input-group-text {
  padding: 20px;
}

/**
* Icons
*/
// Feather Icon
.icon-demo-content i-feather {
  width: 40px !important;
  height: 40px !important;
  line-height: 36px;
  display: block;
  text-align: center;
  margin: 0 auto;
}

.icon-demo-content span {
  margin-top: 8px;
  display: inline-block;
}

body[data-layout-mode="dark"] {

  // Calender
  .fc-theme-standard .fc-scrollgrid,
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: 1px solid $gray-dark-300;
  }

  .fc .fc-daygrid-day-number,
  .fc .fc-col-header-cell-cushion {
    color: $white;
  }

  .fc .fc-col-header-cell-cushion {
    color: $gray-400 !important;
  }

  .ng-select.ng-select-opened > .ng-select-container {
    background: lighten($gray-dark-200, 2%);
  }

  .ng-select .ng-select-container {
    background-color: lighten($gray-dark-200, 2%);
    border: 1px solid $gray-dark-300;
  }

  .ng-dropdown-panel {
    background-color: lighten($gray-dark-200, 2%);
    border: 1px solid $gray-dark-300;
  }

  /**
    * Projects
    */
  aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):after {
    background-color: $gray-dark-300;
  }

  //Project create
  .dropzone {
    background-color: lighten($gray-dark-200, 2%) !important;
    border-color: $gray-dark-300 !important;
  }

  .dropzone .dz-message {
    background-color: lighten($gray-dark-200, 2%) !important;
    color: $white !important;
  }

  /**
    *   Faqs Page
    */
  .general_faq .collapsed .accordion-button {
    color: $white;
    border-bottom: 1px solid $gray-dark-300;
    box-shadow: none;
    background-color: transparent;
  }

  .general_faq .card-body {
    border-bottom: 1px solid $gray-dark-300;
  }

  .general_faq .card {
    box-shadow: none;
  }

  /**
    *  Ui Elements
    */
  // Alert
  .alert-outline.custom-alert,
  .alert-top-border.custom-alert {
    .close {
      background: transparent;
      border: 0;
      font-size: 10px;
      padding: 1.35rem 1.25rem;
      background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.5;
      width: 1em;
      height: 1em;
      z-index: 2;
      color: $btn-close-color;

      span {
        display: none;
      }
    }
  }

  /**
  * Range Slider
  */
  .ngx-slider .ngx-slider-bar {
    background-color: lighten($gray-dark-200, 2%);
  }


}

/* Table header stickey */
.table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #ffffff;
}

.table-list thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #F3F3F3 !important;
}

.table tbody tr td {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;

  color: #434343;
  height: 43px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.table tbody tr.active td {
  background-color: transparent !important;
}

.table tbody tr.active {
  border-left-width: 4px;
  border-left-style: solid;
  border-left-color: #3f51b5;
  //background: rgba(0, 0, 0, 0.1) !important;
  background: #CBD2ED !important
}

//#b0c4de
/* button box shadow none */
.btn:focus {
  outline: none;
  box-shadow: none;
}


/* layout css */
.mobile-layout-flex-content {
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  position: absolute;
}


body {
  * {
    outline: none;
  }

  height: 100%;
  overflow: hidden;
}

.switch-pane {
  color: white;
}

#layout-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}

// google map
.pac-container {
  z-index: 99999;
}

.main-content {
  height: 100%;

  .page-content {
    height: 100%;
    background-color: #ffffff;
    //background-color: #F3F3F3;

    .container-fluid {
      height: 100%;
    }
  }

}

.content-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #F3F3F3;
}

.search-condition {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 8px;
}

.content-body {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.table-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  //padding-bottom: 136px;
  padding-bottom: 80px;
}

.table-simple-bar {
  height: 100%;
}

.default-simple-bar {
  height: 100%;
}

.non-icon-invalid-feedback {
  color: #f34e4e;
  margin: -12px 0 12px;
  font-size: 12px;
}

// ng-select form-control
.form-control.ng-select {
  padding: 0;
  height: 36px;

  .ng-select-container {
    border: none;
    height: 34px !important;
    min-height: 34px !important;

    .ng-value-container {
      padding-top: 4.3px;

      .ng-placeholder {
        top: 7px;
      }

      .ng-value {
        margin-bottom: 4.4px;
      }
    }
  }

}


.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: none;
}

.required {
  color: #ff544d;
  padding-left: 2px;
}

.card.card-nobody {
  box-shadow: none;
  border-radius: unset;
  height: 100%;
  width: 100%;
  border: none;
  margin-bottom: 0;
  background: #F3F3F3;

  .card-body {
    padding: 0;
  }
}

.card-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;

  .view-left {
    padding: 20px;
  }


  .view-right {
    padding: 40px;
  }

  .view-left {
    width: 280px;
    min-width: 280px;
    display: flex;
    flex-direction: column;

    .view-left-no-result {
      position: relative;
      width: 100%;
      height: 100%;
    }

  }

  .view-right {
    width: 100%;
    border-left: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;

    background: #F3F3F3;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-top-left-radius: 8px;
  }
}

.page-title-box {
  padding: 16px 8px;
}

.btn-cursor {
  cursor: pointer;
}

.aligner-center {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img-nodata {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.load-ticket {
  height: 100%;
  //padding-bottom: 10px;
  position: relative;
}

.authentication-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;

  .left-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
  }

  .right-item {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
  }

  .auth-page-title {
    font-size: 37px;
    font-weight: 700;
    line-height: 116%;
    color: #060D15;
    text-align: center;
  }

  .auth-page-title-detail {
    font-size: 14px;
    font-weight: 350;
    line-height: 20px;
    color: #000000;
    text-align: center;
  }
}

.v-line {
  background: #D9D9D9;
  height: 1px;
  margin: 17px 0;
}

.pointer {
  cursor: pointer;
}

.form-check-input {
  width: 16px;
  height: 16px;

  border: 1px solid #373F41 !important;
  border-radius: 2px !important;
}

.form-check-input:checked {
  background-color: #373F41;
  border-color: #373F41;
}

.form-check-input[type=radio] {
  border-radius: 50% !important;
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0 12px;
  line-height: 1px;
}

.duration-icon {
  &:hover {
    background-color: #f4f7fa;
  }
}

.input-group-text {
  padding: 4px !important;
}

.dropdown-item {
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  /* identical to box height, or 94% */

  display: flex;
  align-items: center;

  color: #000000;
  text-transform: capitalize;

  //margin-left: -22px;
  height: 40px;
  padding: 0 16px;
  cursor: pointer;
}

.detail-area-container {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  background-color: #ffffff;
}

.detail-area {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  background-color: #f3f3f3;


  .detail-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 18px;
    height: 100%;
    justify-content: space-between;
    border-radius: 10px;
    margin: 10px 0 10px 0;

    &:hover {
      background-color: #b0c4de;
    }
  }

  .detail-row-active {
    background-color: #185caf;
  }

  .list-card-container {
    width: 100%;
    height: 94px;
    background-color: #ffffff;
    margin-right: 20px;
  }

  .detail-item-right {
    align-items: end;
  }

  .detail-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .detail-font-bold-16px {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;

    display: flex;
    align-items: center;

    color: #000000;
  }

  .detail-font-14px {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    display: block;
    align-items: center;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 195px;
    overflow: hidden;
  }

  .detail-font-12px {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    display: flex;
    align-items: center;

    color: #000000;

    width: 230px;
  }
}

#dropdown-dropup {
  //top: 15px !important;
}

.input-calendar-condition {
  width: 100%;
  //width: 116px;
  flex-wrap: nowrap !important;
}

.input-search-condition {
  border-radius: 4px 0 0 4px !important;
  border-radius: 0;
  //border: solid 1px #d0d3de !important;
  border: solid 1px #e2e5e8 !important;
  background-color: #ffffff;
  border-right: 0 !important;
  width: 160px;
}

.search-text {
  background-color: #2f2f38;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.search-text-bold {
  background-color: #2f2f38;

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.detail-label {
  height: 40px;
  vertical-align: middle;
  font-size: 12px;
  color: #818181;
  font-weight: bold;
}

.detail-value {
  height: 28px;
  vertical-align: middle;
  font-size: 12px;
  color: #000000;
  font-weight: normal;
}

.button-small {
  height: 25px;
  padding: 3px 5px 3px 5px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 10px;
}

.custom-today-class {
  background: #f3f3f3;
  border: 1px solid #EBECED;
}

.content-wrapper {
  padding: 40px;
  background-color: #f3f3f3;

  .page-title-box {
    padding: 0 0 23px 0 !important;
  }

  .card {
    .card-body {
      background-color: #f3f3f3;
    }
  }

  h4 {
    font-family: "NotoSansKR", "NotoSans", sans-serif !important;
  }

}

.detail-default {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .detail-container {
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
  }

}


.modal-confirm-text {
  font-size: 14px;
  color: #2f2f38;

  .error-icon {
    stroke: red;
  }

}

.close {
  outline: none !important;
  box-shadow: none !important;
  border: none;
  background: none;
  padding: 0 !important;
}

.search-box {
  input[type="search"].form-control:focus {
    border: 1px solid #e2e5e8 !important;
  }
}


.btn-lg {
  height: 55px;
  font-weight: 700;
  font-size: 16px;
}

.coupon-detail-status-badge {
  width: fit-content;
  margin-left: auto;
}


.fl-100px {
  left: 100px !important;
}

.detail-responsive {
  overflow: hidden;
  height: 0;
  min-height: 100%;
  position: relative;
}

.table-simple-bar {
  padding-right: 8px;
}

.table-simple-bar-right {
  padding-right: 8px;
}

.simple-bar-padding-right {
  padding-right: 8px;
  width: 100%;
  height: 100%;
}

.text-shorten {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// fix nested modal backdrop issue (no backdrop for nested modal)
.modal {
  background: rgba(0, 0, 0, .3);
}

tabset {
  .tab-content {
    height: 100% !important;
  }
}
