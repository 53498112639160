.font-weight-light  { font-weight: $font-weight-light !important; }
.font-weight-normal { font-weight: $font-weight-normal !important; }
.font-weight-bold   { font-weight: $font-weight-bold !important; }
.font-italic        { font-style: italic !important; }

/* padding */
.p-12px {
  padding: 12px !important;
}

/* padding top */
.pt-10px {
  padding-top: 10px !important;
}

.pt-4px {
  padding-top: 4px !important;
}

.pt-8px {
  padding-top: 8px !important;
}

.pt-24px {
  padding-top: 24px !important;
}

.pt-50px {
  padding-top: 50px !important;
}
/* padding bottom */
.pb-320px {
  padding-bottom: 320px !important;
}

.pb-300px {
  padding-bottom: 300px !important;
}

.pb-250px {
  padding-bottom: 250px !important;
}

.pb-200px {
  padding-bottom: 200px !important;
}

.pb-190px {
  padding-bottom: 190px !important;
}

.pb-178px {
  padding-bottom: 178px !important;
}

.pb-160px {
  padding-bottom: 160px !important;
}

.pb-120px {
  padding-bottom: 120px !important;
}

.pb-100px {
  padding-bottom: 100px !important;
}

.pb-40px {
  padding-bottom: 40px !important;
}

.pb-36px {
  padding-bottom: 36px !important;
}

.pb-24px {
  padding-bottom: 24px !important;
}

.pb-16px {
  padding-bottom: 16px !important;
}

.pb-14px {
  padding-bottom: 14px !important;
}

.pb-12px {
  padding-bottom: 12px !important;
}

.pb-10px {
  padding-bottom: 10px !important;
}

.pb-8px {
  padding-bottom: 8px !important;
}

.pb-4px {
  padding-bottom: 4px !important;
}

/* padding right */
.pr-6px {
  padding-right: 6px !important;
}

.pr-8px {
  padding-right: 8px !important;
}

.pr-16px {
  padding-right: 16px !important;
}

.pr-18px {
  padding-right: 18px !important;
}

.pr-20px {
  padding-right: 20px !important;
}

.pr-24px {
  padding-right: 24px !important;
}

.pr-40px {
  padding-right: 40px !important;
}

/* padding left */

.pl-0 {
  padding-left: 0 !important;
}

.pl-4x {
  padding-left: 4px !important;
}

.pl-6px {
  padding-left: 6px !important;
}

.pl-8px {
  padding-left: 8px !important;
}

.pl-10px {
  padding-left: 10px !important;
}

.pl-12px {
  padding-left: 14px !important;
}

.pl-14px {
  padding-left: 14px !important;
}

.pl-16px {
  padding-left: 16px !important;
}

.pl-24px {
  padding-left: 24px !important;
}

.pl-36px {
  padding-left: 36px !important;
}

.pl-48px {
  padding-left: 48px !important;
}

.pl-54px {
  padding-left: 54px !important;
}


/* padding */
.p-8px {
  padding: 8px;
}
.p-10px {
  padding: 10px;
}

.p-16px {
  padding: 16px;
}

.p-24px {
  padding: 24px;
}

.p-40px {
  padding: 40px;
}

/* padding x axis */
.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-16px {
  padding-left: 16px;
  padding-right: 16px;
}

.px-18px {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.px-24px {
  padding-left: 24px;
  padding-right: 24px;
}

.px-40px {
  padding-left: 40px;
  padding-right: 40px;
}

.py-24px {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-20px {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-8px {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-4px {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
/* margin top */
.mt-4px {
  margin-top: 4px !important;
}

.mt-8px {
  margin-top: 8px !important;
}

.mt-12px {
  margin-top: 12px !important;
}

.mt-16px {
  margin-top: 16px !important;
}

.mt-20px {
  margin-top: 20px !important;
}

.mt-24px {
  margin-top: 24px !important;
}

.mt-32px {
  margin-top: 32px !important;
}

.mt-40px {
  margin-top: 40px !important;
}

/* margin bottom */
.mb-2px {
  margin-bottom: 2px !important;
}

.mb-4px {
  margin-bottom: 4px !important;
}

.mb-8px {
  margin-bottom: 8px !important;
}

.mb-10px {
  margin-bottom: 8px !important;
}

.mb-12px {
  margin-bottom: 12px !important;
}

.mb-16px {
  margin-bottom: 16px !important;
}

.mb-24px {
  margin-bottom: 24px !important;
}

.mb-30px {
  margin-bottom: 30px !important;
}

.mb-32px {
  margin-bottom: 32px !important;
}

.mb-36px {
  margin-bottom: 36px !important;
}

.mb-40px {
  margin-bottom: 40px !important;
}

.mb-44px {
  margin-bottom: 44px !important;
}

.mb-64px {
  margin-bottom: 64px !important;
}

.mb-180px {
  margin-bottom: 180px !important;
}

.mb-200px {
  margin-bottom: 200px !important;
}

/* margin right */
.mr-4px {
  margin-right: 4px !important;
}
.mr-8px {
  margin-right: 8px !important;
}

.mr-12px {
  margin-right: 12px !important;
}

.mr-16px {
  margin-right: 16px !important;
}

.mr-22px {
  margin-right: 24px !important;
}

.mr-24px {
  margin-right: 24px !important;
}

.mr-34px {
  margin-right: 34px !important;
}

/* margin left */
.ml-4px {
  margin-left: 4px !important;
}

.ml-8px {
  margin-left: 8px !important;
}

.ml-12px {
  margin-left: 12px !important;
}

.ml-20px {
  margin-left: 20px !important;
}

.ml-24px {
  margin-left: 24px !important;
}

.ml-32px {
  margin-left: 32px !important;
}

.ml-40px {
  margin-left: 40px !important;
}

.ml-88px {
  margin-left: 88px !important;
}

.my-4px {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.my-8px {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-10px {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my-40px {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.m-0 {
  margin: 0 !important;
}

.m-8px {
  margin: 8px !important;
}

/* height */
.h-20px {
  height: 20px;
}

.h-30px {
  height: 30px;
}

.h-40px {
  height: 40px;
}

.h-55px {
  height: 55px;
}

.h-70px {
  height: 70px !important;
}

.h-500px {
  //height: 500px !important;
  max-height: 500px !important;
}
/* width */

/* etc */
.bt {
  border-top: 1px solid #e0e0e0;
}

.bb {
  border-bottom: 1px solid #e0e0e0;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

h4 {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}

h5 {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.color-white {
  color: #ffffff !important;
}

.title-circle {
  color: #DA0000 !important;
  font-size: 8px;
  display: block;
  margin: auto 0 auto 4px;
}

// Components size

.wd-xxs {
  width: $wd-xxs;
}

.wd-xs {
  width: $wd-xs;
}

.wd-sm {
  width: $wd-sm;
}

.wd-sd {
  width: $wd-sd;
}

.wd-md {
  width: $wd-md;
}

.wd-lg {
  width: $wd-lg;
}

.wd-xl {
  width: $wd-xl;
}

.wd-xxl {
  width: $wd-xxl;
}

.wd-wide {
  width: $wd-wide;
}

.wd-auto {
  width: $wd-auto;
}

.wd-zero {
  width: $wd-zero;
}

.wd-fit {
  width: fit-content !important;
}

.wd-max {
  width: max-content !important;
}

.wd-25px {
  min-width: 25px !important;
  width: 25px !important;
}

.wd-48px {
  min-width: 48px !important;
  width: 48px !important;
}

.wd-94px {
  min-width: 94px !important;
  width: 94px !important;
}

.wd-100px {
  min-width: 100px !important;
  width: 100px !important;
}

.wd-116px {
  min-width: 116px !important;
  width: 116px !important;
}

.wd-150px {
  min-width: 150px !important;
  width: 150px !important;
}

.wd-153px {
  min-width: 153px !important;
  width: 153px !important;
}

.wd-164px {
  min-width: 164px !important;
  width: 164px !important;
}

.wd-184px {
  min-width: 184px !important;
  width: 184px !important;
}

.wd-200px {
  min-width: 200px !important;
  width: 200px !important;
}

.wd-206px {
  max-width: 206px !important;
}

.wd-320px {
  min-width: 320px !important;
  width: 320px !important;
}

.mw-130px {
  max-width: 130px !important;
}

.mw-150px {
  max-width: 150px !important;
}

.min-985px {
  min-width: 985px !important;
}

.min-h-100 {
  min-height: 100% !important;
}
