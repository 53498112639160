/*
Template Name: Dashonic - Admin & Dashboard Template
Author: Pichforest
Version: 1.0.0
Website: https://Pichforest.com/
Contact: Pichforest@gmail.com
File: Custom Bootstrap Css File
*/


//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/bootstrap";

@import "components/reboot";
@import "components/backgrounds";
@import "components/badge";
@import "components/buttons";
@import "components/breadcrumb";
@import "components/card";
@import "components/carousel";
@import "components/dropdown";
@import "components/nav";
@import "components/table";
@import "components/pagination";
@import "components/progress";
//@import "components/popover";
@import "default";
