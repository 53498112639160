//
// _table.scss
//


.table {
  th {
    //font-weight: $table-th-font-weight;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #000000;
  }

  .table-light {
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}


.table-responsive {
  background-color: #F3F3F3;
  overflow: hidden;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 12px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($dark, .2);
    border-radius: 10px;
    border: 2px solid $white;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $white;
  }

  height: 0;
  min-height: 100%;
  padding-top: 36px;
  //border-top: 2px solid #e0e0e0;
  position: relative;
}

body[data-layout-mode="dark"] {
  // table
  .table {
    border-color: lighten($gray-dark-200, 5%);
    color: $gray-dark-400;
  }

  .table-bordered {
    border-color: $gray-dark-300;

    th,
    td {
      border-color: $gray-dark-300;
    }
  }

  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: $gray-dark-300;
  }

  .table-striped > tbody > tr:nth-of-type(odd),
  .table-hover > tbody > tr:hover,
  .table .table-light {
    --bs-table-accent-bg: #03304b;
    color: $gray-dark-400;
  }

  // table
  .table-dark {
    background-color: $gray-dark-300;

    > :not(caption) > * > * {
      background-color: $gray-dark-300;
    }
  }

  .table-active {
    background-color: lighten($gray-dark-200, 2%) !important;
    color: $gray-dark-400;

    th, td {
      background-color: lighten($gray-dark-200, 2%);
    }
  }

  .table-responsive {

    &::-webkit-scrollbar-thumb {
      background-color: rgba($white, .075);
      border: 2px solid $gray-dark-200;
    }

    &::-webkit-scrollbar-track {
      background-color: $gray-dark-200;
    }
  }
}

/*table {
  border-spacing: 0 10px;
  border-collapse: separate;
  margin-top: -10px;
  margin-bottom: 0 !important;

  th {
    border-bottom: 0 !important;
  }

  tbody {
    tr {
      //border-spacing: 0 20px;
      background-color: #ffffff !important;
      box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;

      td:first-child {
        border-radius: 5px 0 0 5px;
      }

      td:last-child {
        border-radius: 0 5px 5px 0;
      }
    }

  }
}*/

.table-list {
  border-spacing: 0 10px;
  border-collapse: separate;
  margin-top: -10px;
  margin-bottom: 0 !important;

  th {
    border-bottom: 0 !important;
  }

  tbody {
    tr {
      //border-spacing: 0 20px;
      background-color: #ffffff !important;
      box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;

      td:first-child {
        border-radius: 5px 0 0 5px;
      }

      td:last-child {
        border-radius: 0 5px 5px 0;
      }
    }

  }
}

.table tbody tr:hover td {
  //background-color: #CBD2ED;
  background-color: #b0c4de;
  cursor: pointer;
}
