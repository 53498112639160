//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

.btn {
  cursor: pointer;
  box-shadow: none !important;
  outline: none !important;

  /*&:hover,
  &:active,
  &:focus {
    border: solid 1px transparent !important;
  }*/

  &:disabled {
    border: 1px solid transparent !important;
  }
}

.btn.btn-default {
  &:hover,
  &:active,
  &:focus {
    border: 1px solid transparent !important;
  }
}

.btn-warning {
  color: $white !important;
}

//
// Soft Buttons
//

@mixin button-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.1);
  border-color: transparent !important;

  // &:focus,
  // &:active,
  &:hover {
    color: $white;
    background-color: $bg;
  }

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($bg, 0.5);
  }
}

@each $color,
$value in $theme-colors {
  .btn-soft-#{$color} {
    @include button-variant-soft($value);
  }
}

// Soft Light button
.btn-soft-light {
  color: $gray-600;

  &:hover,
  &:focus,
  &:active {
    color: $gray-800;
  }
}

// Soft Light button
.btn-outline-light {
  color: $dark;

  &:hover,
  &:focus,
  &:active {
    color: $dark;
  }
}

//
// Split Dropdown buttons
//

@mixin button-variant-split($bg) {
  background-color: lighten($bg, 5%);
  border: none;
}

@each $color,
$value in $theme-colors {
  .btn-#{$color}.dropdown-toggle-split {
    @include button-variant-split($value);
  }
}

.btn-light.dropdown-toggle-split {
  background-color: darken($light, 3%);;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}


body[data-layout-mode="dark"] {

  // buttons
  .btn-light {
    color: $gray-dark-600;
    background-color: $gray-dark-300;
    border-color: $gray-dark-300 !important;
  }

  .btn-outline-light {
    color: $gray-dark-500;
    border-color: $gray-dark-300;

    &:hover {
      color: $gray-dark-500;
      background-color: $gray-dark-300;
      border-color: $gray-dark-300;
    }
  }

  .btn-check:focus + .btn-light,
  .btn-light:focus,
  .btn-check:focus + .btn-dark,
  .btn-dark:focus,
  .btn-check:focus + .btn-outline-light,
  .btn-outline-light:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($gray-dark-300, .5);
  }

  .btn-soft-dark, .btn-outline-dark {
    color: $gray-dark-600;
  }

  // buttons
/*  .btn-dark {
    background-color: lighten($gray-dark-200, 2%);
    border-color: lighten($gray-dark-200, 2%);
  }

  .btn-outline-dark {
    border-color: lighten($gray-dark-200, 2%);

    &:hover {
      background-color: lighten($gray-dark-200, 2%);
      border-color: lighten($gray-dark-200, 2%);
    }
  }

  .btn-soft-dark {
    background-color: rgba($gray-dark-200, 0.25);
    border-color: rgba($gray-dark-200, 0.25);

    &:hover {
      background-color: lighten($gray-dark-200, 2%);
      border-color: lighten($gray-dark-200, 2%);
    }
  }*/
}

.btn-dark {
  background-color: $black !important;
  border-color: $black !important;
  color: #FFFFFF !important;

  &:hover {
    background-color: lighten($black, 50%) !important;
    border-color: lighten($black, 50%) !important;
    color: #FFFFFF !important;
  }
  &:active, &:focus {
    background-color: #FFFFFF !important;
    border-color: $black !important;
    color: #000000 !important;
  }
  &:disabled {
    opacity: unset !important;
    background-color: lighten($black, 40%) !important;
    border-color: lighten($black, 40%) !important;
    color: #818181 !important;
  }
}

.btn-outline-dark {
  background-color: #FFFFFF !important;
  border-color: #000000 !important;
  color: #000000 !important;

  &:hover {
    background-color: lighten($black, 50%) !important;
    border-color: lighten($black, 50%) !important;
    color: #FFFFFF !important;
  }
  &:active, &:focus {
    background-color: $black !important;
    border-color: $black !important;
    color: #FFFFFF !important;
  }
  &:disabled {
    opacity: unset !important;
    background-color: lighten($black, 40%) !important;
    border-color: lighten($black, 40%) !important;
    color: #818181 !important;
  }
}

.btn-primary.btn-xxs {
  color: #ffffff;
  background-color: #1F2D67;
  font-style: normal;

  font-weight: 500;
  font-size: 16px;
  line-height: 15px;

  display: flex;
  align-items: center;
  padding-left: 20px;

  text-transform: capitalize;
}

.btn-outline-primary.btn-xxs {
  border-color: #1F2D67;
  background-color: transparent;
  color: #1F2D67;
}

.btn-border-radius {
  border-radius: 20px !important;
}

.btn-mobile {
  height: 42px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
}

.btn-112px {
  width: 112px;
}

.btn-185px {
  width: 187px;
  height: 55px;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  text-transform: capitalize;
}

.btn-226px {
  width: 226px;
  height: 55px;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  text-transform: capitalize;
}

.btn-gray {
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.7);
  color: #FFFFFF;
}

.dropdown-toggle {
  height: 55px;
  background-color: rgba(0, 0, 0, 0.7);
  //border: 1px solid rgba(0, 0, 0, 0.7);
  color: #FFFFFF;
  border: none;
  &:hover, &:active, &:focus {
    border: none;
  }

  font-awesome {
    font-size: 16px;
  }
}

//Bootstrap Dropdown
.dropdown-toggle::after {
  display: none;
}

//.btn-primary.btn-xxs:focus,
//.btn-primary.btn-xxs:hover {
//  background-color: #1F2D67 !important;
//  outline: none;
//  box-shadow: none;
//  border: 1px solid #1F2D67;
//}

/*
.btn-filter:hover {
  background: rgba(31, 45, 103, 0.5) !important;
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(31, 45, 103, 0.5);
}
*/

.dropdown-toggle {
  &:hover,
  &:active,
  &:focus {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.btn-group > .btn:not(:first-child) {
  //margin-left: 1px;
}

.btn-h-line {
  width: 1px;
  background: #fff;
  position: absolute;
  height: 40px;
  left: 308px;
  margin: 8px 0;
  z-index: 9999;
}

.btn-badge-outline {
  height: 21px !important;
  padding: 1px 12px !important;
  border-radius: 10px !important;

}
