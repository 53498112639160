@import '../variables';

// Import module styling
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";

/* Fix header text placement */
.bs-datepicker-container {
  padding: 16px 12px;
}

.bs-datepicker {
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #eaeaea;
  background-color: #ffffff;
}

.bs-datepicker-head > bs-datepicker-navigation-view {
  display: flex;
  justify-content: space-around;
}

/* Custom Theme */
.theme-dk {
  .bs-datepicker-head {
    background-color: $info;
  }

  .bs-datepicker-body {
    padding: 20px !important;
  }

  .bs-datepicker-body table:not(.months):not(.years) tr th:first-child,
  .bs-datepicker-body table:not(.months):not(.years) tr td:first-child {
    color: #ff0000;

    .is-other-month {
      color: #ff5353 !important;
      opacity: 0.5;
    }
  }

  .bs-datepicker-body table th {
    color: #787f96;
  }

  .bs-datepicker-body table td {
    color: #595e6e;
  }

  .bs-datepicker-body table td {
    span.selected, &.selected span,
    span[class*="select-"]:after,
    &[class*="select-"] span:after {
      background-color: #4a5ed1;
    }

    &.week span {
      color: $info;
    }
  }

  .bs-datepicker-multiple:first-child {
    bs-datepicker-navigation-view {
      margin-right: -20px;
      width: 152px;
      float: right;
    }
  }

  .bs-datepicker-multiple:last-child {
    bs-datepicker-navigation-view {
      padding-left: 30px;
      width: 100px;
    }
  }

  .bs-datepicker-head > bs-datepicker-navigation-view {
    justify-content: center;
  }

  .bs-datepicker-head {
    background-color: transparent;

    button {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #2f2f38;
    }

    button.previous {
      margin-right: -7px;
      margin-top: -1px;

      span {
        font-weight: 100;
        color: #767676;
      }
    }

    button.next {
      margin-left: 15px;
      margin-top: -1px;

      span {
        font-weight: 100;
        color: #767676;
      }
    }

    button[disabled],
    button:hover {
      background-color: transparent !important;
      border: none !important;
    }

    button.current {
      padding: 0 0 0 4px;
    }
  }

  .bs-datepicker-body {
    border: none;
  }

  .bs-datepicker-multiple + .bs-datepicker-multiple {
    margin-left: 0;

    .bs-datepicker-body {
      border-left: solid 1px #dbdbdb !important;
    }
  }

  bs-month-calendar-view:first-child,
  bs-years-calendar-view:first-child,
  bs-days-calendar-view:first-child {
    .bs-datepicker-body {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 0;
    }
  }

  bs-month-calendar-view:last-child,
  bs-years-calendar-view:last-child,
  bs-days-calendar-view:last-child {
    .bs-datepicker-body {
      //border-left: solid 1px #dbdbdb !important;
      padding-top: 0;
      padding-bottom: 0;
      min-height: 0;
    }
  }

  .bs-datepicker-body table.days td.active:not(.select-start):before,
  .bs-datepicker-body table.days td.in-range:not(.select-start):before,
  .bs-datepicker-body table.days span.active:not(.select-start):before,
  .bs-datepicker-body table.days span.in-range:not(.select-start):before {
    background: #eef0fe;
    height: 28px;
    top: 2px;
  }

  .bs-datepicker-body table.days span.select-start.selected:before {
    background: #eef0fe;
    left: 10px;
    height: 28px;
    top: 2px;
  }

  .bs-datepicker-body table.days span.in-range.is-highlighted:before,
  .bs-datepicker-body table.days span.in-range.select-end:before {
    right: 10px;
    left: 0;
  }

  /*bs-month-calendar-view:last-child:not(.datepicker-multiple),
  bs-years-calendar-view:last-child:not(.datepicker-multiple),
  bs-days-calendar-view:last-child:not(.datepicker-multiple) {
    .bs-datepicker {
      &-head {
        &:after {
          content: "-";
          display: inline-block;
          vertical-align: top;
          width: 6px;
          height: 24px;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.25;
          letter-spacing: normal;
          text-align: left;
          color: #2f2f38;
          margin-top: -33px;
          margin-left: -12px;
        }
      }
    }
  }*/
}
