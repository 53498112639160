//
// _helper.scss
//

.font-size-8 {
  font-size: 8px !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-26 {
  font-size: 26px !important;
}

.font-size-100 {
  font-size: 100px !important;
}


// Font weight help class

.fw-medium {
  font-weight: $font-weight-medium;
}

.fw-semibold {
  font-weight: $font-weight-semibold;
}

.bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: .7;
  background-color: $black;
}

//list-group-item

.list-group-item{
  color: $body-color;
}

// Social

.social-list-item {
  height: 2.4rem;
  width: 2.4rem;
  line-height: calc(2.4rem - 2px);
  display: block;
  border: 1px solid $card-bg;
  border-radius: 50%;
  color: $text-muted;
  text-align: center;
  transition: all 0.4s;
  &:hover{
    color: $dark;
  }
}


.w-xs {
  min-width: 80px;
}
.w-sm {
  min-width: 95px;
}
.w-md {
  min-width: 110px;
}
.w-lg {
  min-width: 140px;
}

.w-xl {
  min-width: 160px;
}


// alert

.alert-dismissible {
  .btn-close{
    font-size: 10px;
    padding: $alert-padding-y * 1.4 $alert-padding-x;
  }
}

body[data-layout-mode="dark"] {
    .btn-close {
      background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
    }
    // border


    .border-bottom{
        border-bottom: 1px solid $gray-dark-300 !important;
    }

    .border-top,
    .border,
    .list-group-item {
        border-color: $gray-dark-300 !important;
    }

    @each $color, $value in $theme-colors {
      .border-#{$color} {
          border-color: $value !important;
      }
    }

    .border-light{
        border-color: $gray-dark-300 !important;
    }

    // text colors
    .text-dark{
        color: $gray-dark-800 !important;
    }

    .text-muted{
        color: $gray-dark-400 !important;
    }

    .text-body{
        color: $gray-dark-500 !important;
    }

    // List item
    .list-group-item {
        background-color: $gray-dark-200;
        color: $gray-dark-400;
    }

    // img thumbnail

    .img-thumbnail{
        background-color: lighten($gray-dark-200,2.5%);
        border-color: $gray-dark-300;
    }

    // popover-header
    //.popover-header{
    //    color: $gray-dark-200;
    //}

    .custom-blockpuote.blockquote.blockpuote-outline-dark{
      border-color: $gray-dark-300;
      .blockquote-footer{
        color: $gray-dark-300;
      }
    }

    .social-list-item{
      border: 1px solid $gray-dark-200;
      color: $gray-dark-400;
      &:hover{
        color: $gray-dark-500;
      }
    }

}
