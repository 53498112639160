//
// _modal.scss
//

body[data-layout-mode="dark"] {
  // modal
  .modal-header, .modal-footer {
    border-color: $gray-dark-300;;
  }
}

.modal-header {
  padding: 40px;

  h4 {
    font-family: "NotoSansKR", "NotoSans", sans-serif;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 18px !important;

    color: #000000 !important;
  }

  //padding: 28px 28px 18px 66px;
  padding: 40px 40px;
  border: none;

  .modal-close {
    box-sizing: content-box;
    color: #000000;
    background: transparent;
    border: 0; // for button elements
    padding: 0;
    margin-right: -5px;

    font-awesome {
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
}

.modal-body {
  margin: 0 40px;
  padding: 0 !important;
  //background: #F3F3F3;
  //border-radius: 5px;
}

.modal-footer {
  border-top: none;
  //padding: 52px 66px 54px;
  padding: 40px;

  > * {
    margin: 0;
  }
}

.modal-1036px {
  max-width: 1036px !important;
}

.modal-xxl {
  max-width: 1320px !important;
}

.modal-customer-search {
  max-width: 840px !important;
  height: 800px !important;
}

.modal-content {
  border: none !important;
}

.modal-customer-create {
  max-width: 844px !important;
  //height: 543px !important;
}

.modal-filter-name {
  max-width: 940px !important;
}


.modal-file-upload {
  max-width: 1600px !important;
}



.modal-btn-default {
  width: 100%;
  button {
    height: 55px;
    font-weight: 500;
    font-size: 16px;
  }
}
