//
// Google font - Roboto
//

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');


@font-face {
  font-family: 'NotoSansKR';
  font-style: normal;
  font-weight: 100;
  src: local('Noto Sans Light'), local('NotoSansKR-Light');
  src: url(~assets/fonts/NotoSansKR-Thin.woff2) format('woff2'),
  url(~assets/fonts/NotoSansKR-Thin.woff) format('woff');;
}

@font-face {
  font-family: 'NotoSansKR';
  font-style: normal;
  font-weight: 300;
  src: local('Noto Sans Light'), local('NotoSansKR-Light');
  src: url(~assets/fonts/NotoSansKR-Light.woff2) format('woff2'),
  url(~assets/fonts/NotoSansKR-Light.woff) format('woff');;
}

@font-face {
  font-family: 'NotoSansKR';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans Regular'), local('NotoSansKR-Regular');
  src: url(~assets/fonts/NotoSansKR-Regular.woff2) format('woff2'),
  url(~assets/fonts/NotoSansKR-Regular.woff) format('woff');
}

@font-face {
  font-family: 'NotoSansKR';
  font-style: normal;
  font-weight: 500;
  src: local('Noto Sans Medium'), local('NotoSansKR-Medium');
  src: url(~assets/fonts/NotoSansKR-Medium.woff2) format('woff2'),
  url(~assets/fonts/NotoSansKR-Medium.woff) format('woff');
}

@font-face {
  font-family: 'NotoSansKR';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Sans Bold'), local('NotoSansKR-Bold');
  src: url(~assets/fonts/NotoSansKR-Bold.woff2) format('woff2'),
  url(~assets/fonts/NotoSansKR-Bold.woff) format('woff');
}


@font-face {
  font-family: 'NotoSansKR';
  font-style: normal;
  font-weight: 900;
  src: local('Noto Sans Bold'), local('NotoSansKR-Bold');
  src: url(~assets/fonts/NotoSansKR-Black.woff2) format('woff2'),
  url(~assets/fonts/NotoSansKR-Black.woff) format('woff');
}

