/*
@import '~@fortawesome/fontawesome-free/css/brands.css';
@import '~@fortawesome/fontawesome-free/css/regular.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';
@import '~@fortawesome/fontawesome-free/css/fontawesome.css';
*/


//Spinner
@import "./node_modules/ngx-spinner/animations/line-spin-clockwise-fade";
@import "./node_modules/ngx-spinner/animations/square-jelly-box";
@import "./node_modules/ngx-spinner/animations/ball-scale-pulse";


//Fonts
@import "app/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "app/variables";
@import "./node_modules/bootstrap/scss/mixins.scss";


// Structure
@import "app/structure/general";
@import "app/structure/topbar";
@import "app/structure/page-head";
@import "app/structure/footer";
@import "app/structure/right-sidebar";
@import "app/structure/vertical";
@import "app/structure/horizontal-nav";
@import "app/structure/layouts";

// Components
@import "app/components/avatar";
@import "app/components/alerts";
@import "app/components/accordion";
@import "app/components/helper";
@import "app/components/preloader";
@import "app/components/modals";
@import "app/components/forms";
@import "app/components/widgets";
@import "app/components/demos";
@import "app/components/print";


// Plugins
@import "app/plugins/custom-scrollbar";
@import "app/plugins/calendar";
@import "app/plugins/range-slider";
@import "app/plugins/sweatalert2";
@import "app/plugins/alertify";
@import 'app/plugins/swiper-slider';
@import "app/plugins/choices";
@import "app/plugins/switch";
@import "app/plugins/gridjs";
@import "app/plugins/colorpicker";
@import "app/plugins/datepicker";
@import "app/plugins/form-editors";
@import "app/plugins/form-upload";
@import "app/plugins/apexcharts";
@import "app/plugins/lightbox";
@import "app/plugins/google-map";
@import "app/plugins/vector-maps";

// Pages
@import "app/pages/authentication";
@import "app/pages/kanbanboard";
@import "app/pages/file-manager";
@import "app/pages/email";
@import "app/pages/chat";
@import "app/pages/coming-soon";
@import "app/pages/timeline";
@import "app/pages/extras-pages";

// Custom Scss
@import "app/input";
@import "app/icons";
//@import "app/default";
@import "app/override/popover";
@import "app/override/tooltip";
@import "app/override/datepicker";
@import "app/custom";
